import React, { useEffect, useState } from "react"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"
import { Helmet } from "react-helmet"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import NumberFormat from "react-number-format"
import { navigate } from "gatsby"
import { useMediaPredicate } from "react-media-hook"
import Select from "react-select"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Section, Container } from "../../components/grid/grid"
import formBackgroundImg from "../../assets/img/wheels/enchante-spin-wheel.png"

import headerSound from "../../assets/audio/smart-move-jingle2.mp3"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const GET_PHONENUMBER = gql`
  query formSpinWheelEnchante {
    formSpinWheelEnchante {
      phoneNumber
      project
      unit
    }
  }
`

const EnchantePage = location => {
  const [getResult, { data }] = useLazyQuery(GET_PHONENUMBER)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const ogUrl = process.env.BASE_URL + "/enchante-add/"

  let listOfProduct = [{ value: "Enchante", label: "Enchante" }]

  const LeadForm = ({ errors, touched, values, setFieldValue, btnLoading }) => {
    const phoneNumberHandler = (val, setFieldValue) =>
      setFieldValue("phoneNumber", val)

    return (
      <Form className="row pt-4" id="spin-wheel-enchante-form">
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama</label>
          <Field
            name="name"
            className="form-control px-0"
            placeholder="Nama Anda"
            type="text"
            id="customerName"
          />
          {touched.name && errors.name && (
            <div className="invalid-value d-block">{errors.name}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nomor Handphone</label>
          <NumberFormat
            name="phoneNumber"
            className="form-control px-0"
            placeholder="Nomor Handphone"
            allowEmptyFormatting
            prefix="+62"
            value={values.phoneNumber}
            onValueChange={val =>
              phoneNumberHandler(val.formattedValue, setFieldValue)
            }
            id="customerPhone"
          />
          {touched.phoneNumber && errors.phoneNumber && (
            <div className="invalid-value d-block">{errors.phoneNumber}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Email</label>
          <Field
            name="email"
            className="form-control px-0"
            placeholder="Email Anda"
            type="text"
            id="customerEmail"
          />
          {touched.email && errors.email && (
            <div className="invalid-value d-block">{errors.email}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Produk Yang Dibeli</label>
          <Select
            className="form-control px-0"
            classNamePrefix="Produk Yang Dibeli"
            options={listOfProduct}
            value={{ value: "Enchante", label: "Enchante" }}
            id="product"
            isDisabled={true}
          />
          {touched.product && errors.product && (
            <div className="invalid-value d-block">{errors.product}</div>
          )}
        </div>
        <div className="col-12 col-md-12 pb-3">
          <label className="text-muted my-0">Nama Sales</label>
          <Field
            name="salesName"
            className="form-control px-0"
            placeholder="Nama sales"
            type="text"
            id="salesName"
          />
        </div>
        <div className="col-12 col-md-12 pb-3 pt-3" id="disclaimer">
          <label className="text-muted my-0">
            <Field
              type="checkbox"
              name="isAgree"
              value="isAgree"
              id="isAgree"
            />
            &nbsp;Saya menyetujui bahwa keputusan Spin to Wheel 2024 produk
            enchante tidak dapat diganggu gugat.
          </label>
          {touched.isAgree && errors.isAgree && (
            <div className="invalid-value d-block">{errors.isAgree}</div>
          )}
        </div>
        <div className="col-12 pt-5">
          <button className="btn btn-primary w-100 w-md-25">
            {btnLoading ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
          </button>
        </div>
      </Form>
    )
  }

  useEffect(() => {
    if (location.uri == "/enchante-add") {
      let navbarDesktop = document
        .getElementById("desktopNav")
        .querySelectorAll(".nav-link")

      if (resizeScreen) {
        document.getElementsByClassName(
          "logo-secondary"
        )[1].style.display = `none`
        document.getElementsByClassName("logo-main")[1].style.display = `block`
      } else {
        document.getElementsByClassName(
          "logo-secondary"
        )[0].style.display = `none`
        document.getElementsByClassName("logo-main")[0].style.display = `block`
      }

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
      document.querySelector("#lang-wrapper").classList.remove("d-flex")
      document.querySelector("#lang-wrapper").classList.add("d-none")
    }
  }, [])

  useEffect(() => {
    getResult()
  }, [])

  const [formValues, setFormValues] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    product: listOfProduct[0].value,
    salesName: "",
    isAgree: "",
  })

  const EnhancedLeadForm = withFormik({
    mapPropsToValues({
      name,
      phoneNumber,
      email,
      product,
      salesName,
      isAgree,
    }) {
      return {
        name: formValues.name || name,
        email: formValues.email || email,
        phoneNumber: formValues.phoneNumber || phoneNumber,
        product: formValues.product || product,
        salesName: formValues.salesName || salesName,
        isAgree: formValues.isAgree || isAgree,
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required"),
      phoneNumber: Yup.string().required("Phone number is required"),
      product: Yup.string().required("Produk Yang Dibeli is required"),
      salesName: Yup.string(),
      isAgree: Yup.string().required("Disclaimer is required"),
    }),
    handleSubmit(values) {
      setFormValues(values)

      let dataList = data.formSpinWheelEnchante
      // let counter = 0

      for (let i = 0; i < dataList.length; i++) {
        if (
          dataList[i].phoneNumber == values.phoneNumber.split("+").join("") &&
          dataList[i].project.trim() == values.product.trim()
        ) {
          console.log(">>1")
          alert(
            "Maaf! Untuk dapat mengikuti program Enchante Spin To Wheel kembali, Anda harus memilih unit yang berbeda dengan sebelumnya."
          )
          return false
        }
      }

      console.log(">>5")
      let form = document.getElementById("spin-wheel-enchante-form")
      for (let q = 0; q < form.elements.length; q++) {
        let eSecond = form.elements[q]
        if (eSecond.type === "button") continue
        let idSecond = eSecond.id
        var valueSecond = eSecond.value
        sessionStorage.setItem(idSecond, valueSecond)
      }

      // set product value in session storage
      sessionStorage.setItem("product", listOfProduct[0].value)

      navigate("/enchante-add/spin")
    },
  })(LeadForm)

  useEffect(() => {
    if (resizeScreen) {
      // document.querySelector("#stw-enchante-section").style.background = `url('${extraKetupatBackground}') no-repeat`
      document.querySelector(
        "#stw-enchante-section"
      ).style.backgroundColor = `#fff`
      document.querySelector(
        "#stw-enchante-section"
      ).style.backgroundSize = `cover`
    } else {
      // document.querySelector("#stw-enchante-section").style.background = `url('${extraKetupatBackground}') no-repeat`
      document.querySelector(
        "#stw-enchante-section"
      ).style.backgroundColor = `#fff`
      document.querySelector(
        "#stw-enchante-section"
      ).style.backgroundSize = `contain`
    }
  }, [])

  return (
    <Layout
      location={location}
      currentLocation={location.uri}
      isApartemen={true}
      soundFile={headerSound}
    >
      <SEO
        title={`Promo Enchante : Dapatkan Additional Discount Mulai dari 2-4%`}
        fbTitle={`Promo Enchante : Dapatkan Additional Discount Mulai dari 2-4%`}
        twitterTitle={`Promo Enchante : Dapatkan Additional Discount Mulai dari 2-4%`}
        description={`Dapatkan additional discount khusus produk Enchante dengan range 2-4% melalui sistem spin wheel`}
        fbDescription={`Dapatkan additional discount khusus produk Enchante dengan range 2-4% melalui sistem spin wheel`}
        twitterDescription={`Dapatkan additional discount khusus produk Enchante dengan range 2-4% melalui sistem spin wheel`}
        url={ogUrl}
        img={formBackgroundImg}
        fbImg={formBackgroundImg}
        twitterImg={formBackgroundImg}
      />
      <Helmet>
        <body className="bd-page" />
        <meta
          name="keywords"
          content="spin wheel enchante 2024, diskon properti sinar mas land, program spin wheel enchante."
        ></meta>
        <meta name="copyright" content="2024 | Sinar Mas Land"></meta>
      </Helmet>
      <Section
        className="main-apartemen-stw bg-gray-50"
        id="stw-enchante-section"
      >
        <Container className="surpriseForm">
          <EnhancedLeadForm />
        </Container>
      </Section>
    </Layout>
  )
}
export default EnchantePage
